import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import try_free_bg from "../assets/images/try_free_bg.png";
import { BackIcon } from "../assets/icons/backIcon";
import { CheckIcon } from "../assets/icons/checkIcon";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CustomLink from "../components/CustomLink";

const TrialMeetingSucccessContent = ({ data }) => {
  const _data = data?.prismicTrialMeetingConfirmation?.data || {};
  const [features] = _data?.body?.filter(
    (v) => v.slice_type === "features_descriptions"
  ) || [[]];
  const [brands] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];
  const dataImage = getImage(_data?.success_section_image);
  return (
    <section>
      <div className="h-screen !text-left grid grid-cols-12">
        <div className="relative col-span-12 lg:col-span-7">
          <img
            src={try_free_bg}
            alt="background"
            className="absolute inset-0 w-full h-full -z-10 bg-[#212233]"
            loading="lazy"
            width={0}
            height={0}
          />
          <section className="px-8 pt-10 pb-10 md:pt-16 md:pb-0 md:px-16">
            <CustomLink to="/" className="z-10 flex items-center gap-2">
              <span>
                <BackIcon />
              </span>
              <h4 className="text-xl font-semibold text-white leading-6 tracking-[-0.04em]">
                Back
              </h4>
            </CustomLink>
            <div
              dangerouslySetInnerHTML={{ __html: _data?.page_heading?.html }}
              className="text_bg font-semibold text-[40px] md:text-[80px] mt-20 leading-[70px] md:leading-[96.82px] text-white"
            />

            <section className="mt-9 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-6 border-b-[0.5px] border-b-slate-500">
              {features?.items?.map((data, index) => (
                <section className="flex col-span-1 gap-3" key={index}>
                  <span className="mt-[3px]">
                    <CheckIcon />
                  </span>
                  <p className="text-base leading-7 text-white">
                    {data?.feature_description_text?.text}
                  </p>
                </section>
              ))}
            </section>

            <p className="text-base font-semibold leading-7 text-center text-white mt-11">
              Used by the world’s leading computer vision teams to accelerate
              model development
            </p>

            <div className={`mt-6 grid grid-cols-2 gap-8 md:grid-cols-6`}>
              {brands?.items?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
                  >
                    <img
                      src={x?.brand_logo?.url}
                      alt={x?.brand_logo?.alt || `brand_icon_${i}`}
                      className="object-scale-down h-24 w-96 image_grayscale"
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="py-6 lg:py-auto md:py-auto my-auto col-span-12 lg:col-span-5 px-[50px]">
          <div className="flex items-center justify-center">
            <GatsbyImage
              loading="lazy"
              image={dataImage}
              alt={_data?.success_section_image?.alt || "img"}
              className="w-72"
            />
          </div>
          <div className="flex items-center justify-center">
            <div>
              <span
                className="text_bg_sccess_heading text-[36px] sm:text-[40px] md:text-[60px] text-blue-1000 leading-11 sm:leading-12 md:leading-18 tracking-[-0.03em] md:tracking-[-0.065em] font-semibold  "
                dangerouslySetInnerHTML={{
                  __html: _data?.success_message_heading?.html,
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-center text-center">
            <div
              className="w-80"
              dangerouslySetInnerHTML={{
                __html: _data?.success_message_description?.html,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const MeetingSuccess = (props) => {
  return <TrialMeetingSucccessContent {...props} />;
};

export const query = graphql`
  query {
    prismicTrialMeetingConfirmation {
      data {
        body {
          ... on PrismicTrialMeetingConfirmationDataBodyContactSalesQuotes {
            id
            slice_type
            items {
              icon {
                alt
                url
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
              quote {
                html
                text
              }
            }
          }
          ... on PrismicTrialMeetingConfirmationDataBodyFeaturesDescriptions {
            id
            slice_type
            items {
              feature_description_text {
                html
                text
              }
            }
          }
          ... on PrismicTrialMeetingConfirmationDataBodyTrustedBrandsList {
            id
            items {
              brand_logo {
                alt
                url
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
            }
            slice_type
          }
        }
        description {
          html
          text
        }
        page_heading {
          html
          text
        }
        quotes_heading {
          html
          text
        }
        success_message_description {
          html
          text
        }
        success_message_heading {
          html
          text
        }
        meta_header {
          html
          text
        }
        meta_description {
          html
          text
        }
        success_section_image {
          alt
          url
          gatsbyImageData(width: 400, placeholder: BLURRED)
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicTrialMeetingConfirmation?.data || {};

  return (
    <SEO
      title={_data?.meta_header?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default MeetingSuccess;
